@charset "UTF-8";

.content_all {
  width: 100%;
}

.event_back {
  background: #f4f4f4;
  height: 100vmax;
}

.content_inner,
.content_blkshrink,
.content_blk,
.content_fit,
.content_blkfit {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

a {
  color: #4792e0;
}

.event_searchButton {
  margin: 20px 0px;
  text-align: center;
}

.event_wrap {
  padding-bottom: 30px;
  background: #f4f4f4;
}

.event_block {
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
}

.card_body {
  padding: 10px;
}

.event_name {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.text_center {
  color: black;
  font-size: 16px;
  border: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 3px;
  margin-bottom: -30px;
}

.event_title {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  width: 210px;
}

.event_logo {
  width: 145px;
  margin-right: -10px;
}

.event_summary {
  text-align: left;
  margin: 10px 0px;
  font-size: 16px;
  margin-bottom: 20px;
}

.event_part_state_green {
  background-color: #87bb57;
  color: white;
  font-weight: bold;
  font-size: 20px;
  height: 54px;
  text-align: center;
  padding-top: 11px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.event_part_state_blue {
  background-color: #4b98c7;
  color: white;
  font-weight: bold;
  font-size: 20px;
  height: 54px;
  text-align: center;
  padding-top: 11px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.card-header {
  padding-top: 0.6em;
}

.event_detail {
  padding: 0.5em 0.7em;
  margin: 0, 10px;
  background: #f4f4f4;
  border-radius: 10px;
}

.event_hr {
  margin-top: 3px;
  margin-bottom: 8px;
}

.item_name {
  color: #87bb57;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
}

.item_name_text {
  color: black;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
}

.top_explain {
  padding: 20px 30px 0 30px;
  text-align: left;
  font-weight: bold;
}
